import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout/Layout";

const outer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const block = {
  width: "30%",
};

const pic = {
  maxWidth: "100%",
  width: "auto",
  height: "auto",
};

const head = {
  fontSize: "2.3rem",
};

const sub = {
  fontSize: "1.2rem",
};

const NotFoundPage = () => {
  return (
    <Layout>
      <div style={outer}>
        <div style={block}>
          <h1 style={head}>You appear to be lost...</h1>

          <p style={sub}>
            <Link to='/'>Retrace your steps back home</Link>
          </p>
        </div>
        <div style={block}>
          <StaticImage style={pic} src='../images/not-found.png' alt='' />
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
